<template>
  <div class="recent-activity pb-4">
    <div class="content-title h5 mt-7 font-weight-bold">
      {{ $t('latestActivity') }}
    </div>
    <CyEventsTimeline
      v-if="events.length"
      :show-tags="false"
      :indent-items="true"
      :events="events.slice(0, 10)"/>
    <v-card
      v-else
      class="d-flex justify-center text-center mt-4 mb-2 py-4"
      outlined>
      <v-card-text>
        <v-icon
          class="mb-6"
          color="#90A5C1"
          size="26">
          monitor_heart
        </v-icon>
        <div class="h5 mb-2">
          {{ $t('noActivityHeadline') }}
        </div>
        <p class="mb-2">
          {{ $t('noActivityText') }}
        </p>
      </v-card-text>
    </v-card>
    <CyButton
      v-if="events.length && !$attrs.loading && allEventsRoute"
      class="mt-2"
      variant="secondary"
      theme="primary"
      :to="allEventsRoute">
      {{ $t('events.viewAllActivity') }}
    </CyButton>
  </div>
</template>

<script>
import CyEventsTimeline from '@/components/CyEventsTimeline.vue'

export default {
  name: 'CyEventsRecentActivity',
  components: {
    CyEventsTimeline,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    allEventsRoute: {
      type: Object,
      validator: (value) => _.has(value, 'name'),
      default: () => {},
    },
  },
  computed: {
  },
  i18n: {
    messages: {
      en: {
        latestActivity: 'Latest activity',
        noActivityHeadline: 'No activity associated with this project',
        noActivityText: 'Events will appear here once you start interacting with this entity.',
      },
      es: {
        latestActivity: 'Actividad recientes',
        noActivityHeadline: 'No hay actividad asociada con este proyecto',
        noActivityText: 'Los eventos aparecerán aquí una vez que empieces a interactuar con esta entidad.',
      },
      fr: {
        latestActivity: 'Activité récente',
        noActivityHeadline: `Aucune activité associée à ce projet`,
        noActivityText: 'Les événements apparaîtront ici lorsque vous commencerez à interagir avec cette entité.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-timeline-item {
  .v-timeline-item__opposite {
    background-color: cy-get-color("grey", "light-4");
  }
}
</style>
