<template>
  <CyMenu
    content-class="menu-actions"
    offset-y
    bottom
    left
    origin="top right">
    <v-list
      class="menu-actions__list">
      <v-list-item
        v-has-rights-to="canChangeStatus"
        class="menu-actions__list-item list-item mt-2"
        @click="$emit('toggle-status')">
        <v-list-item-action>
          <v-icon color="primary">
            {{ pipelineStatusActionInfo.icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <h3 class="list-item__title">
            {{ pipelineStatusActionInfo.text }}
          </h3>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2 mx-0"/>

      <v-list-item
        v-for="{ key, icon, title, action, text = null, hasPermissionToEdit = true } in actions"
        :key="key"
        class="menu-actions__list-item list-item"
        @click="hasPermissionToEdit ? action() : null">
        <CyTooltip
          :disabled="hasPermissionToEdit"
          bottom>
          <template #activator="{ on: onTooltip }">
            <span
              class="height-100 d-flex align-center"
              v-on="onTooltip">
              <v-list-item-action>
                <v-icon color="primary">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <h3 class="list-item__title">
                  {{ title }}
                </h3>
                <p
                  v-if="text"
                  class="list-item__text">
                  {{ text }}
                </p>
              </v-list-item-content>
            </span>
          </template>
          {{ $t('pipelinesEditNoPermissions') }}
        </CyTooltip>
      </v-list-item>
    </v-list>
  </CyMenu>
</template>

<script>
export default {
  name: 'CyViewsEditPipelineMenu',
  props: {
    actions: {
      type: Array,
      required: true,
    },
    canChangeStatus: {
      type: Array,
      required: true,
    },
    pipelineStatus: {
      type: String,
      required: true,
    },
  },
  computed: {
    pipelineStatusActionInfo () {
      const isPaused = _.isEqual(this.pipelineStatus, 'pause')
      return {
        text: isPaused ? this.$t('pipeline.resume') : this.$t('pipeline.pause'),
        icon: isPaused ? 'play_arrow' : 'pause',
      }
    },
  },
  i18n: {
    messages: {
      en: {
        editPipeline: 'Edit pipeline',
        pipelinesEditNoPermissions: `You don't have the required permissions to edit this pipeline`,
      },
      es: {
        editPipeline: 'Editar la pipeline',
        pipelinesEditNoPermissions: `No tiene los permisos necesarios para editar esta pipeline`,
      },
      fr: {
        editPipeline: 'Éditer la pipeline',
        pipelinesEditNoPermissions: `Vous ne disposez pas des autorisations nécéssaires pour modifier cette pipeline`,
      },
    },
  },
}
</script>
