<template>
  <div
    :class="[
      'component-overview', {
        'component-overview--full-screen': isFullScreen,
      }]">
    <div class="component-overview__content">
      <CyEnvComponentPipeline
        v-if="pipeline"
        v-has-rights-to="['GetPipeline', pipeline.name]"
        :pipeline-canonical="pipeline.name"
        :project-canonical="projectCanonical"
        :environment-canonical="envCanonical"
        :component-canonical="componentCanonical"
        :force-full-screen="$route.meta.pipelineOnly"
        @refresh-pipelines-selector="GET_PROJECT_PIPELINES"
        @full-screen="$toggle.isFullScreen($event)"/>

      <CyEventsRecentActivity
        v-if="canSeeEvents && !isFullScreen && !loadingEvents"
        :show-tags="false"
        :indent-items="true"
        :all-events-route="{ name: 'envComponentActivity' }"
        :events="events.slice(0, 10)"/>
    </div>
    <v-slide-x-reverse-transition>
      <div
        v-show="!isFullScreen && !loadingEvents"
        class="component-overview__sidebar">
        <div class="sidebar">
          <div
            v-if="component && component.description"
            class="sidebar__section">
            <div class="sidebar__title">
              {{ $t('about') }}
            </div>
            <p class="mb-0">
              {{ component.description }}
            </p>
          </div>
          <div class="sidebar__section">
            <div class="sidebar__title">
              {{ $t('events.activityOverPastYear') }}
            </div>
            <ECharts
              :loading="loadingEvents"
              class="line-chart"
              theme="cycloid"
              autoresize
              :option="chartOptions"/>
          </div>
          <div
            v-if="component"
            class="sidebar__section">
            <div class="sidebar__title">
              {{ $t('untranslated.stack') }}
            </div>
            <CyWizardServiceCard
              :service="component.service_catalog"
              :on-click-action="() => { isStackPreviewVisible = true }"/>
          </div>
        </div>
      </div>
    </v-slide-x-reverse-transition>
    <portal
      v-if="isStackPreviewVisible"
      to="side-panel">
      <div class="side-panel-backdrop"/>
      <v-slide-x-reverse-transition>
        <v-card
          v-click-outside="{
            handler: closePreview,
            include: getClickOutsideExceptions,
          }"
          aria-label="Stack preview"
          role="region"
          class="side-panel">
          <CyStackPreview
            :stack="component.service_catalog"
            :show-select-btn="false"
            @close="closePreview"/>
        </v-card>
      </v-slide-x-reverse-transition>
    </portal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CyEnvComponentPipeline from '@/components/CyEnvComponentPipeline.vue'
import CyEventsRecentActivity from '@/components/CyEventsRecentActivity.vue'
import CyStackPreview from '@/components/CyStackPreview.vue'
import CyWizardServiceCard from '@/components/CyWizardServiceCard.vue'
import { constructBreadcrumb, extractStartStopPipelines } from '@/utils/helpers'

export default {
  name: 'CyPageEnvComponentOverview',
  components: {
    CyEnvComponentPipeline,
    CyWizardServiceCard,
    CyEventsRecentActivity,
    CyStackPreview,
  },
  breadcrumb () {
    const { projectCanonical, projectName, envCanonical, componentCanonical } = this
    const componentName = this.component?.name || componentCanonical
    return constructBreadcrumb(this.$options.name, componentName, [
      {
        label: this.$t('Components'),
        name: 'environmentOverview',
        params: { projectCanonical, envCanonical },
      },
      {
        label: envCanonical,
        name: 'environment',
        params: { projectCanonical, envCanonical },
      },
      {
        label: this.$t('Environments'),
        name: 'projectOverview',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    const avatar = this.$route.meta.pipelineOnly
      ? {
          type: 'stack',
          item: this.component?.service_catalog,
        }
      : {
          type: 'environmentIcon',
          item: _.find(this.envs, ['canonical', this.envCanonical]),
        }

    return {
      title: `${this.envCanonical} start-stop ${_.toLower(this.$t('untranslated.pipeline'))}`,
      avatar,
    }
  },
  props: {
    projectCanonical: {
      type: String,
      required: true,
    },
    envCanonical: {
      type: String,
      required: true,
    },
    componentCanonical: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isFullScreen: false,
    pipeline: null,
    loadingEvents: true,
    isStackPreviewVisible: false,
  }),
  computed: {
    ...mapState('organization', {
      events: (state) => state.available.events,
    }),
    ...mapState('organization/project', {
      pipelines: (state) => state.pipelines,
    }),
    ...mapGetters('organization/project', [
      'envs',
      'envComponent',
    ]),
    component () {
      const env = _.find(this.envs, ['canonical', this.envCanonical])
      if (!env || !env.components) {
        return null
      }
      return this.envComponent(this.envCanonical, this.componentCanonical)
    },
    canSeeEvents () {
      return this.$cycloid.permissions.canDisplay('GetEvents')
    },
    eventFilters () {
      return {
        'project_canonical[in]': this.projectCanonical,
        'environment_canonical[in]': this.envCanonical,
        'component_canonical[in]': this.componentCanonical,
        begin: 0,
        end: $date.format(Date.now(), 'T'),
      }
    },
    chartOptions () {
      const daysOfPastYear = _.reverse(Array.from({ length: 365 }, (_, days) => {
        const today = new Date()
        today.setDate(today.getDate() - days)
        return $date.format(today, 'MMM d, yyyy')
      }))

      return {
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        series: [{
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: '#54BABA',
          },
          areaStyle: {
            color: '#E8F5F5',
          },
          data: daysOfPastYear.map((day) => ([day, this.events.filter(({ timestamp }) => $date.isSameDay(new Date(day), new Date(timestamp)))?.length])),
        }],
        tooltip: {
          trigger: 'axis',
          valueFormatter: (value) => `${value} ${this.$t('Events').toLowerCase()}`,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: daysOfPastYear,
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          type: 'value',
        },
      }
    },
  },
  watch: {
    componentCanonical () {
      this.setPipeline()
      this.getEvents()
    },
    envCanonical () {
      this.setPipeline()
      this.getEvents()
    },
  },
  async created () {
    if (this.$route.meta.pipelineOnly) this.isFullScreen = true
    await this.GET_PROJECT_PIPELINES()
    this.setPipeline()
    await this.getEvents()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/project', [
      'GET_PROJECT_PIPELINES',
    ]),
    setPipeline () {
      const pipelines = extractStartStopPipelines(this.pipelines)?.[this.$route.meta.startStopPipeline ? 'startStop' : 'regular']
      if (_.isEmpty(pipelines)) return
      const pipeline = _.find(pipelines, ({ project, environment, component }) => {
        return project.canonical === this.projectCanonical &&
          environment.canonical === this.envCanonical &&
          component.canonical === this.componentCanonical
      })
      if (!pipeline) return
      this.pipeline = pipeline
    },
    async getEvents () {
      if (this.canSeeEvents) {
        await this.FETCH_AVAILABLE({
          keyPath: 'events',
          extraParams: [this.eventFilters],
        })
      }
      this.loadingEvents = false
    },
    closePreview () {
      this.isStackPreviewVisible = null
    },
    getClickOutsideExceptions () {
      const selectors = [
        '.main-nav a',
        '.main-nav button',
        '.dev-layer',
        '.v-menu__content',
      ]
      return Array.from(document.querySelectorAll(selectors.join(', ')))
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.envComponentPipeline',
        choosePipelineHint: 'Please choose a pipeline',
        createPipelineBtn: 'Create a pipeline',
        editPipelineBtn: 'Edit a pipeline',
      },
      es: {
        title: '@:routes.envComponentPipeline',
        choosePipelineHint: 'Por favor, elija un pipeline',
        createPipelineBtn: 'Crear un pipeline',
        editPipelineBtn: 'Editar un pipeline',
      },
      fr: {
        title: '@:routes.envComponentPipeline',
        choosePipelineHint: 'Veuillez selectionner une pipeline',
        createPipelineBtn: 'Créer une pipeline',
        editPipelineBtn: 'Editer une pipeline',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.component-overview {
  display: flex;
  position: relative;
  width: 100%;
  margin-top: -24px;

  &__content {
    display: flex;
    flex: 1 0 68%;
    flex-direction: column;
    padding: #{$spacer-6} 0 #{$spacer-6} 0;
  }

  &__sidebar {
    flex: 1 0 28%;
    padding: #{$spacer-6} 0 #{$spacer-8} #{$spacer-6};
  }

  .sidebar {
    display: flex;
    flex-direction: column;

    &__section {
      margin-top: $spacer-6;

      &:first-child {
        margin-top: 0;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: $spacer-2;
      font-weight: $font-weight-bolder;
    }
  }
}

.side-panel {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 830px;
  height: calc(100% - #{$offset} * 2);

  &-backdrop {
    position: fixed;
    z-index: 108;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 0.5);
  }
}

.line-chart {
  height: 50px;
}
</style>
