var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CyMenu',{attrs:{"content-class":"menu-actions","offset-y":"","bottom":"","left":"","origin":"top right"}},[_c('v-list',{staticClass:"menu-actions__list"},[_c('v-list-item',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:(_vm.canChangeStatus),expression:"canChangeStatus"}],staticClass:"menu-actions__list-item list-item mt-2",on:{"click":function($event){return _vm.$emit('toggle-status')}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.pipelineStatusActionInfo.icon)+" ")])],1),_c('v-list-item-content',[_c('h3',{staticClass:"list-item__title"},[_vm._v(" "+_vm._s(_vm.pipelineStatusActionInfo.text)+" ")])])],1),_c('v-divider',{staticClass:"my-2 mx-0"}),_vm._l((_vm.actions),function(ref){
var key = ref.key;
var icon = ref.icon;
var title = ref.title;
var action = ref.action;
var text = ref.text; if ( text === void 0 ) text = null;
var hasPermissionToEdit = ref.hasPermissionToEdit; if ( hasPermissionToEdit === void 0 ) hasPermissionToEdit = true;
return _c('v-list-item',{key:key,staticClass:"menu-actions__list-item list-item",on:{"click":function($event){hasPermissionToEdit ? action() : null}}},[_c('CyTooltip',{attrs:{"disabled":hasPermissionToEdit,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('span',_vm._g({staticClass:"height-100 d-flex align-center"},onTooltip),[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(icon)+" ")])],1),_c('v-list-item-content',[_c('h3',{staticClass:"list-item__title"},[_vm._v(" "+_vm._s(title)+" ")]),(text)?_c('p',{staticClass:"list-item__text"},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('pipelinesEditNoPermissions'))+" ")])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }